import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import Drawer from '@material-ui/core/Drawer';
import { useEffect, useState } from "react";
import ApiTrans from "../../services/trans/trans";
import { useQuery } from "react-query";
// import { fetchAllFunds } from "../../services/fetchAllFunds";
// import { getFundsCategorisation } from "../../services/getFundsCategorisation";
import ProductList from '../common/form/productList';
// import NFOCountDownTimer from '../organism/nfoCountdownTimer';
import DistributorServicesPopup from './DistributorServicesPopup';
import InvestorServicesPopup from './InvestorServicesPopup';
import MobileMenu from "./MobileMenu";
import OurFundsPopup from './OurFundsPopup';
import "./headerRevamp.css";
import LoginButton from './loginButton';
// import { nfoCountdownTimerData as data } from '../../utils/helpers';
import { useMediaQuery } from '@material-ui/core';
import WhatsappPromoHeader from './WhatsappHeaderPromo';

let apiTrans = new ApiTrans();

const dropDownOptions = [
  { value: "mf", label: "Mutual Fund", fullText: "Mutual Fund", link: process.env.REACT_APP_MOTILAL_MF_MODULE_URL },
  { value: "pms", label: "PMS", fullText: "Portfolio Management Services (PMS)", link: process.env.REACT_APP_MOTILAL_PMS_MODULE_URL },
  { value: "aif", label: "AIF", fullText: "Alternative Investment Funds (AIF)", link: process.env.REACT_APP_MOTILAL_AIF_MODULE_URL },
  { value: "pt", label: "Partners", fullText: "Partners", link: "/" }
];

const Index = () => {
  const [selectedOption, setSelectedOption] = useState("pt");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [popularFunds, setPopularFunds] = useState([])
  const [categoryFundCount, setCategoryFundCount] = useState({})
  const [scrollPosition, setScrollPosition] = useState(0)
  const isMobileHeader = useMediaQuery("(max-width:989px)")
  // const showNFOCountdownTimer = data.NFO_IS_START
  const showNFOCountdownTimer = true

  const toggleMobileMenu = (value) => {
    setIsMobileMenuOpen(value);
  };


  // const { data: allFunds } = useQuery("allFunds", fetchAllFunds, {
  //   // initialData: allFundsResp,
  //   staleTime: 5 * 60 * 1000,
  //   cacheTime: 10 * 60 * 1000,
  // });

  // Handle for 'options' in Autocomplete being undefined in case of no response.
  // if (!allFunds) allFunds = []

  // const { data: fundCategorisationList } = useQuery("fundCategorisation", getFundsCategorisation, {
  //   staleTime: 5 * 60 * 1000,
  //   cacheTime: 10 * 60 * 1000,
  // })

  // Sort the funds alphabetically by schName
  // const sortedFunds = allFunds?.sort((a, b) =>
  //   a.schDetail.schName.localeCompare(b.schDetail.schName)
  // );

  // useEffect(() => {
  //   // Get number of funds in each category (tag)
  //   let tempCategoryFundCount = {}
  //   allFunds?.forEach(fund => {
  //     fund?.tags?.map(tag => {
  //       if (tag in tempCategoryFundCount) tempCategoryFundCount[tag] += 1
  //       else tempCategoryFundCount[tag] = 1
  //     })
  //   })
  //   setCategoryFundCount(tempCategoryFundCount)

  //   const allPopularSchemes = fundCategorisationList?.sort?.find(
  //     (item) => item.sortName === "Popular"
  //   )?.schemes
  //   const activeAndIndexSchemes = fundCategorisationList?.fundType?.find(
  //     (item) => item?.typeName === "Active" || item?.typeName === "Index"
  //   )?.schemes

  //   let popularSchemesDetails = []
  //   allPopularSchemes?.forEach(popularSchemeCode => {
  //     let popularSchemeDetail = allFunds?.find(fund => fund.schCode === popularSchemeCode && !fund.schDetail.isNFO && activeAndIndexSchemes?.includes(fund.schCode))
  //     // eslint-disable-next-line no-unused-expressions
  //     popularSchemeDetail ? popularSchemesDetails.push(popularSchemeDetail) : null
  //   })

  //   setPopularFunds(popularSchemesDetails?.slice(0, 4))
  // }, [allFunds, fundCategorisationList])


  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY)
    };

    const handleResize = () => {
      setIsMobileMenuOpen(false)
    }

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  useEffect(() => {
    const root = document.querySelector(':root')
    if (showNFOCountdownTimer) {
      if (isMobileHeader)
        root.style.setProperty('--top-for-menu-popup', scrollPosition > 60 ? '100px' : '160px');
      else
        root.style.setProperty('--top-for-menu-popup', scrollPosition > 60 ? '100px' : '160px');
    }
  }, [showNFOCountdownTimer, isMobileHeader, scrollPosition])

  // console.log('PratikMogaveera', fundCategorisationList, allFunds, popularFunds, categoryFundCount)

  return (
    <>
      {/* <NFOCountDownTimer
        isLoginPage={true}
        showCounterTimer={true}
      /> */}

      <WhatsappPromoHeader />

      <header className="headerRevamp tw-sticky tw-top-0 tw-left-0 tw-w-full tw-z-[1000]">
        <div className="tw-hidden tab:tw-block">
          <Desktop
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            // allFunds={sortedFunds}
            popularFunds={popularFunds}
            categoryFundCount={categoryFundCount}
          />
        </div>
        <div className="tab:tw-hidden tw-block">
          <Mobile
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
          // allFunds={sortedFunds}
          />
          <Drawer
            open={isMobileMenuOpen}
            onClose={() => toggleMobileMenu(false)}
            anchor="left">
            <MobileMenu
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              // allFunds={allFunds}
              popularFunds={popularFunds}
              categoryFundCount={categoryFundCount}
              options={dropDownOptions}
              customicon={KeyboardArrowDownRoundedIcon}
            />
          </Drawer>
        </div>
      </header>
    </>
  );
};

const Desktop = ({
  selectedOption,
  setSelectedOption,
  allFunds,
  popularFunds,
  categoryFundCount,
}) => {
  const [openMenuItem, setOpenMenuItem] = useState("")

  return (
    <div className="tw-text-headerlinks tw-h-[100px] tw-bg-white tw-flex tw-border tw-border-b tw-border-new">
      <section className="container tw-flex">
        <div className="tw-mb-auto">
          <a
            href="/"
            title="Motilal Oswal Asset Management Company"
            className="tw-relative tw-block tw-h-[60px] tw-w-[155px] tw-mr-5">
            <img
              src="/assets/images/footer/mo_mf_logo_blue.svg"
              alt="Motilal Oswal Asset Management Company Logo"
              priority
              style={{ width: 'inherit' }}
            />
          </a>
        </div>

        <div className="tw-flex tw-w-full">
          {/* Left Section */}
          <nav className="tw-my-5">
            <div className="tw-w-2/5 tw-rounded-lg fund-select">
              <ProductList
                defaultValue={selectedOption}
                value={selectedOption}
                options={dropDownOptions}
                customicon={KeyboardArrowDownRoundedIcon}
              />
            </div>
            <ul className="tw-mt-3 tw-flex tw-items-center tw-gap-x-[30px] tw-p-0 tw-list-none">
              {/* <li className="menu-item" onMouseEnter={() => setOpenMenuItem("our-funds")} onMouseLeave={() => setOpenMenuItem("")}>
                <a
                  href={`/mutual-funds`}
                  title="Our Funds"
                  className="tw-text-white tw-text-[13px] desktop:tw-text-[16px] tw-leading-6 tw-inline tw-font-medium tw-relative tw-pb-5 after:tw-absolute after:tw-left-0 after:tw-bottom-2 after:tw-w-full after:tw-h-1 after:desktop:tw-h-1 after:tw-content-[''] after:tw-bg-yellow after:tw-transition-opacity after:tw-duration-300 after:tw-opacity-0 hover:tw-text-yellow hover:after:tw-opacity-100">
                  Our Funds
                </a>
                {openMenuItem === "our-funds" ? <OurFundsPopup popularFunds={popularFunds} categoryFundCount={categoryFundCount} /> : null}
              </li> */}


              <li className="menu-item" onMouseEnter={() => setOpenMenuItem("investor-services")} onMouseLeave={() => setOpenMenuItem("")}>
                <p
                  className="tw-text-headerlinks tw-text-[13px] desktop:tw-text-[16px] tw-leading-6 tw-cursor-default tw-inline tw-font-medium tw-m-0 tw-relative tw-pb-5 after:tw-absolute after:tw-left-0 after:tw-bottom-2 after:tw-w-full after:tw-h-1 after:desktop:tw-h-1 after:tw-content-[''] after:tw-bg-yellow after:tw-transition-opacity after:tw-duration-300 after:tw-opacity-0 hover:tw-text-yellow hover:after:tw-opacity-100">
                  Investor Services
                </p>
                {openMenuItem === "investor-services" ? <InvestorServicesPopup /> : null}
              </li>

              <li className="menu-item" onMouseEnter={() => setOpenMenuItem("partner-services")} onMouseLeave={() => setOpenMenuItem("")}>
                <p
                  className="tw-text-headerlinks tw-text-[13px] desktop:tw-text-[16px] tw-leading-6 tw-m-0 tw-cursor-default tw-inline tw-font-medium tw-relative tw-pb-5 after:tw-absolute after:tw-left-0 after:tw-bottom-2 after:tw-w-full after:tw-h-1 after:desktop:tw-h-1 after:tw-content-[''] after:tw-bg-yellow after:tw-transition-opacity after:tw-duration-300 after:tw-opacity-0 hover:tw-text-yellow hover:after:tw-opacity-100">
                  Partner Services
                </p>
                {openMenuItem === "partner-services" ? <DistributorServicesPopup /> : true}
              </li>

              {openMenuItem !== "" ? <div id="header-menu-overlay" className="tw-fixed tw-z-[-1] tw-top-[var(--top-for-menu-popup)] tw-left-0 tw-bg-[rgba(0,0,0,0.5)] tw-h-screen tw-w-screen tw-transition-transform tw-backdrop-blur-[1px] tw-invisible" /> : null}
            </ul>
          </nav>

          {/* Right Section */}
          <div className="tw-ml-auto tw-mt-4 tw-mb-5">
            {/* Top Section */}
            <div className='tw-flex tw-items-center tw-text-[12px] tw-leading-6'>
              <div className='tw-flex tw-items-center tw-pr-5 tw-border-r tw-border-buttonBorderGrey'>
                <div className='tw-h-[24px] tw-w-[24px] after:tw-block after:tw-w-[24px] after:tw-h-[24px] after:tw-bg-[url(/assets/images/header/whatsapp-icon.png)] after:tw-bg-cover'>
                  <img src="/assets/images/header/whatsapp-icon.png" className='tw-block tw-h-[24px] tw-w-[24px] tw-object-contain' />
                </div>
                <a href={"tel:+91 73049 21822"} title="+91 73049 21822" className='hover:tw-underline tw-text-[13px] tw-text-headerlinks hover:tw-text-headerlinks desktop:tw-text-sm'>
                  +91 73049 21822
                </a>
              </div>


              <div className="tw-flex tw-items-center tw-gap-2 tw-px-5 ">
                <p className="tw-text-[13px] desktop:tw-text-sm tw-m-0">Download App</p>
                <div className='tw-flex tw-gap-2 tw-items-center'>
                  <a href="https://apps.apple.com/bw/app/mo-partner-digi-connect/id6502832515" title="Apple Appstore" target="_blank" rel="noreferrer">
                    <img src="/assets/images/footer/appstore_mobile.png" alt="Apple Appstore" height={18} width={18} className='tw-block' />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.moamc.distributorApp&hl=en_IN&gl=US" title="Google Playstore" target="_blank" rel="noreferrer">
                    <img src="/assets/images/footer/playstore_mobile.png" alt="Google Playstore" height={18} width={18} className='tw-block' />
                  </a>
                </div>
              </div>
              <LoginButton />
            </div>

          </div>
        </div>
      </section>
    </div>
  );
};

const Mobile = ({
  selectedOption,
  setSelectedOption,
  handleProductChange,
  setIsMobileMenuOpen,
}) => {

  return (
    <div className="tw-text-headerlinks tw-bg-white tw-flex tw-items-center ">
      <div className="container tw-h-[100px]">
        <div className="tw-flex tw-items-center tw-border-b tw-border-[#3E3A38] tw-py-2">
          <div className="tw-w-fit fund-select">
            <ProductList
              defaultValue={selectedOption}
              value={selectedOption}
              options={dropDownOptions}
              customicon={KeyboardArrowDownRoundedIcon}
            />
          </div>
        </div>

        <div className="tw-flex tw-items-center tw-mt-3">
          <div className="tw-flex tw-gap-4 tw-items-center">
            <button onClick={() => setIsMobileMenuOpen(true)} className='tw-bg-transparent tw-p-0 tw-border-none'>
              <img src="/assets/images/header/mobile_menu.png" alt="Menu Icon" className='tw-h-6 tw-w-6 tw-object-contain' />
            </button>
            <a
              href="/"
              title="Motilal Oswal Asset Management Company"
              className="tw-relative tw-h-[48px] tw-w-[50px]">
              <img
                src="/assets/images/header/MO_monogram.webp"
                alt="Motilal Oswal Asset Management Company Logo"
                className='tw-h-full tw-w-full'
                priority
              />
            </a>
          </div>
          <div className="tw-ml-auto">
            <LoginButton variant="outlined" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
