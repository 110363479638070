const socialsList = [
  {
    title: "Facebook",
    link: "https://www.facebook.com/motilaloswalamc",
    image: "/assets/images/footer/facebookIcon.svg"
  },
  {
    title: "Instagram",
    link: "https://www.instagram.com/motilaloswalamc",
    image: "/assets/images/footer/instagramIcon.svg"
  },
  {
    title: "X",
    link: "https://twitter.com/MotilalOswalAMC",
    image: "/assets/images/footer/excludeIcon.svg"
  },
  {
    title: "Youtube",
    link: "https://youtube.com/motilaloswalamc",
    image: "/assets/images/footer/youtubeIcon.svg"
  },
  {
    title: "Linkedin",
    link: "https://www.linkedin.com/company/motilal-oswal-amc",
    image: "/assets/images/footer/linkedinIcon.svg"
  },
]

const appLinksList = [
  {
    title: "Google Play",
    link: "https://play.google.com/store/apps/details?id=com.moamc.distributorApp&hl=en_IN&gl=US",
    image: "/assets/images/footer/playstoreIcon.svg",
    width: 135
  },
  {
    title: "Apple Appstore",
    link: "https://apps.apple.com/bw/app/mo-partner-digi-connect/id6502832515",
    image: "/assets/images/footer/appstoreIcon.svg",
    width: 120
  },
]

const appLinksListMobile = [
  {
    title: "Google Play",
    link: "https://play.google.com/store/apps/details?id=com.moamc.distributorApp&hl=en_IN&gl=US",
    image: "/assets/images/footer/playstore_mobileIcon.svg",
    width: 135
  },
  {
    title: "Apple Appstore",
    link: "https://apps.apple.com/bw/app/mo-partner-digi-connect/id6502832515",
    image: "/assets/images/footer/appstore_mobileIcon.svg",
    width: 120
  },
]

const copyrightLinks = [
  {
    title: "Disclaimer",
    link: `/disclaimer`
  },
  {
    title: "Voting Policies",
    link: `/assets/pdf/voting-policy.pdf`,
    external: true,
  },
  {
    title: "Privacy Policy",
    link: `/privacy-policy`
  },
  {
    title: "Terms and Conditions",
    link: `/privacy-policy`
  },
  {
    title: "Sitemap",
    link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}sitemap`
  },
]

const footerLinks = {
  "Mutual Fund": [
    {
      title: "Indian Equity Funds",
      link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}mutual-funds/category/indian-equity`
    },
    {
      title: "International Equity Funds",
      link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}mutual-funds/category/international-equity`
    },
    {
      title: "Hybrid & Balanced Funds",
      link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}mutual-funds/category/hybrid-balanced`
    },
    {
      title: "Multi Asset Funds",
      link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}mutual-funds/category/multi-asset`
    },
    {
      title: "Commodity Funds",
      link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}mutual-funds/category/commodity`
    },
    {
      title: "Debt & Liquid Funds",
      link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}mutual-funds/category/debt-liquid`
    },
    {
      title: "Index Funds",
      link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}index-fund`
    },
    {
      title: "ETFs",
      link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}etf`
    }
  ],
  "Services": [
    {
      title: "Request Reports/Statement",
      link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}reporthub`
    },
    {
      title: "Update KYC",
      link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}modify-kyc`
    },
    {
      title: "Unclaimed Redemption",
      link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}mutualfund/unclaimed/login`
    },
    {
      title: "MITRA",
      link: "https://app.mfcentral.com/links/inactive-folios",
      external: true,
    },
  ],
  "Tools": [
    {
      title: "SIP Returns Calculator",
      link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}sip-calculator`
    },
    {
      title: "Compare Portfolio",
      link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}tools/compare-portfolio`
    },
    {
      title: "Compounding Magic",
      link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}tools/compounding-magic`
    }
  ],
  "Group Websites": [
    {
      title: "Financial Services",
      link: "https://www.motilaloswalgroup.com/",
      external: true,
    },
    {
      title: "Broking & Distribution",
      link: "https://www.motilaloswal.com/",
      external: true,
    },
    {
      title: "Private Wealth Management",
      link: "https://www.motilaloswalpwm.com/",
      external: true,
    },
    {
      title: "Home Finance",
      link: "https://motilaloswalhf.com/",
      external: true,
    },
    {
      title: "Institutional Equities",
      link: "https://www.motilaloswalgroup.com/Our-Businesses/Institutional-Equities",
      external: true,
    },
    {
      title: "Private Equity",
      link: "https://www.motilaloswalalt.com/",
      external: true,
    },
    {
      title: "Investment Banking",
      link: "https://www.motilaloswalgroup.com/Our-Businesses/Investment-Banking",
      external: true,
    },
    {
      title: "Commodities",
      link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}mutual-funds/category/commodity`,
      external: true,
    },
  ],
  "Other Links": [
    {
      title: "About Us",
      link:`${process.env.REACT_APP_MOTILAL_PARTNER_AMC_MODULE_URL}about-us/overview`
    },
    {
      title: "Contact Us",
      link: `${process.env.REACT_APP_MOTILAL_PARTNER_AMC_MODULE_URL}contactUs`
    },
    {
      title: "Investor Charter",
      link: `${process.env.REACT_APP_MOTILAL_MF_MODULE_URL}assets/pdf/Investor_Charter.pdf`,
      external: true
    },
    {
      title: "International Business",
      link: `${process.env.REACT_APP_MOTILAL_PARTNER_AMC_MODULE_URL}InternationalBusiness`
    },
    {
      title: "FAQs",
      link: `${process.env.REACT_APP_MOTILAL_PARTNER_AMC_MODULE_URL}faq`,
    }
  ],
}

export {
  appLinksList,
  appLinksListMobile,
  copyrightLinks,
  socialsList,
  footerLinks,
}

