export const MIN_SIP_AMT = 10000;
export const MIN_LUMPSUM_AMT = 40000;

export const Msg = {
  panNo: "Invalid Pan Number",
  mobileNo: 'Invalid Mobile No',
  otp: "Invalid OTP",
}

export const logInMenuItemValues = {
  mutualFund: "mutualFund",
  pms: "pms",
  distributor: "distributor"
};

export const loginOptions = {
  otp: "otp",
  password: "password"
};

//#region Maintain these value with server data value
export const loginMode = {
  PANNO: 1,
  UserId: 2,
  MobileNo: 3,
  EmailId: 4,
  FolioNo: 5
};

export const credentialMode = {
  otp: 1,
  password: 2,
  mPin: 3
}
//#endregion

export const regEx = {
  number: /^[0-9\b]+$/,
  panRegex: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
  userIdRegex: /^([a-zA-Z]){1}[0-9]*$/,
  //number: /^[0-9]*$/,
  mobileNumber: /^[6-9]\d{9}$/,
  otpRegex: /^[0-9]*$/,
  email: /^([a-z0-9,!\#\$%&'\*\+\/=\?\^_`\{\|\}~-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z0-9,!\#\$%&'\*\+\/=\?\^_`\{\|\}~-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*@([a-z0-9-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z0-9-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+){0,1}\.(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]){2,3})$/i,
  alphaNumeric: /^[a-zA-Z0-9-]*$/,
  alphabates: /^[a-zA-Z]*$/,
  alphabatesWithSpace: /^[a-zA-Z ]*$/,
  float: /^\d+(\.\d+)?$/,
  numberWithSingleDecimal: /^[0-9]*\.?[0-9]*$/,
  alphaNumericWithHypen: /^[a-zA-Z0-9-]*$/,
  alphaNumericAdd: /^[a-zA-Z0-9\s,-]*$/,
  alphaNumericMandatory: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/,
  indianMobileNumber: /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/,
}



/* all info read while submitting switch */
const SUBMIT_TEXT = `I/we have and understand the content of the SID/SAI &amp; KIM of the
respective Scheme(s) and agreeto abide by the terms and conditions mentioned
therein and agree to receive all communications from Motilal Oswal Mutual Fund to
the registered Email Id / Mobile Number in my folio(s).`

export const SWITCH_SUBMIT = {
  text: SUBMIT_TEXT,
  label: "Yes, Switch Fund",
  name: 'switchFund',
  value: 'switchFund'
}
/* */
export const STP_SUBMIT = {
  text: SUBMIT_TEXT,
  label: "Yes, STP Fund",
  name: 'STPFund',
  value: 'STPFund'
}

export const REDEEM_SUBMIT = {
  text: SUBMIT_TEXT,
  label: "Proceed",
  name: 'Proceed',
  value: 'Proceed'
}




/*euin declaration */
export const EUIN_DECLARE_TEXT = ` I/We hereby confirm that the EUIN box has been intentionally
left blank by me/us as this transacation is an executed without any
interaction oradvice by the employee/relationship manager/sales person
of th above distributor/sub broker or notwithstanding the advice of in-approateness,
if any, provided by the employee/relationship
manager/sales person of the distributor/sub broker.`


export const patternType = {
  alphaNumeric: 'alphaNumeric',
  alphabates: 'alphabates',
  numeric: 'numeric',
  float: 'float'
}

export const transactType = {
  switch: 'switch',
  redeem: 'redeem',
  stp: 'stp'
}

export const dateValidationText = {
  minDate: 'STP start date cannot be before ',
  fromDate: 'From Date cannot be greater than To Date',
  fromDateEqual: 'To Date cannot be equal to From Date',
  dateFrequency: 'Number of Installments cannot be less than '
}

export const stpValidationText = {
  minSchemeAmount: 'Your amount should be greater than '
}

export const responseError = {
  requestFail: "Something went wrong"
}

export const redeemPages = {
  redeem: 'redeem',
  confirm: 'confirm',
  redemption: 'redemption',
  success: 'success',
}

export const redeemPagesSequence = [redeemPages.redeem, redeemPages.confirm, redeemPages.redemption, redeemPages.success]

export const stpPages = {
  stpStaticPage: 'stpStaticPage',
  success: 'success',
}

export const stpPagesSequence = [stpPages.stpStaticPage, stpPages.success]

export const switchPages = {
  from: 'from',
  to: 'to',
  success: 'success',
}

export const switchPagesSequence = [switchPages.from, switchPages.to, switchPages.success]

export const trans = {
  returnUrl: process.env.PG_RETURN_URL,
  // returnUrl: 'http://localhost:8080/paymentResponse',
}

export const MediaRoom = {
  // PressRelease: ["Business", "Title", "Date", "Place"],
  PressRelease: ["Title", "Date", "Place"],
  Print: ["Title", "Date", "Publication"],
  Online: ["Title", "Date", "Website"],
  PressReleaseResponse: ["business", "contenttitle", "publishDate", "publication"],
  PrintResponse: ["business", "contenttitle", "publishDate", "publication"],
  OnlineResponse: ["business", "contenttitle", "publishDate", "website"]
}

export const reportsTable = {
  SipHeader: ["Scheme Name", "Type", "Plan", "Reg Date", "Start Date", "End Date", "Frequency", "Status", "Folio No", "Amount", "URN No"],
}

export const E_MANDATE_LINK_SENT_MSG = "E-mandate link has been sent to the investor.";

export const infoIconCalc = {
  invtAmt: `Lump sum amount you want to invest.`,
  withDrawFreq: `The frequency at which your withdrawal amount will be credited to your registered bank account`,
  startDate: `You will start receiving your withdrawal amount from this date.`,
  tenure: `Your withdrawal will continue for the selected tenure from the start date.`,
  withDrawPerc: `Percentage of amount you wish to withdraw annually from your investment amount`,
  expRtn: `Your expected returns on the investment for the selected tenure.`,
  withDrawAmt: `Fixed amount you want to receive as your regular monthly income`,
}

export const otpMasterType = {
  transactionType: "TransactionOTP",
  loginType: "LoginOTP",

  //tranact
  switchType: "SwitchOTP",
  stpType: "STPOTP",

  // redeem
  swpType: "SWPOTP",
  redeemType: "RedeemOTP",//sell
}

// ===================================================================
// NFO CHANGE START
// ===================================================================
export const NFO_DATA = {
  NFO_IS_ACTIVE: false,
  NFO_IS_SMART_SWITCH_ACTIVE: true,
  NFO_START_DATE: "2025-02-24",
  NFO_END_DATE: "2025-03-11", // T + 1
  NFO_END_DATE_TEXT: "10th March, 2025",
  NFO_ALLOTMENT_DATE: "2025-03-17",
  NFO_SCHEME_NAME: "Motilal Oswal Active Momentum Fund",
  NFO_SCHEME_CODE: "",
  NFO_LANDING_PAGE_URL: "motilal-oswal-active-momentum-fund", // url after mutual-funds/ to redirect to fund landing page
  NFO_PASSCODE_GIF_SRC: "login/NFO_passcode_banner.jpg"
}
// ===================================================================
// NFO CHANGE END
// ===================================================================