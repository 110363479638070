import { useState } from "react";
import SelectList from "../common/form/selectList";
import ProductList from "../common/form/productList";
import {
  fundTypes,
  importantLinks,
  mobileHeaderLinks,
  distImportantLinks,
} from "./constants";
import LoginButton from "./loginButton";

const MobileMenu = ({
  setIsMobileMenuOpen,
  selectedOption,
  setSelectedOption,
  categoryFundCount,
  allFunds,
  popularFunds,
  classes,
  options,
  onChange,
  customicon,
}) => {
  const [openSection, setOpenSection] = useState("");
  const handleClick = (section) =>
    setOpenSection((prev) => (section === prev ? "" : section));

  return (
    <div
      className="tw-w-screen tw-h-full tw-flex tw-flex-col"
      id="mobile-header-menu"
    >
      <div className="tw-flex tw-mx-4 tw-items-center tw-border-b tw-border-greyBorder tw-py-2">
        <div className="tw-w-fit fund-select">
          {/* <SelectList
            defaultValue={selectedOption}
            value={selectedOption}
            options={options}
            onChange={onChange}
            customicon={customicon}
          /> */}
          <ProductList
            defaultValue={selectedOption}
            value={selectedOption}
            options={options}
            onChange={onChange}
            customicon={customicon}
          />
        </div>
      </div>

      <div className="tw-flex tw-justify-between tw-items-center tw-py-3 tw-px-4">
        <div className="tw-flex tw-items-center tw-gap-4">
          <button
            onClick={() => setIsMobileMenuOpen(false)}
            className="after:tw-block after:tw-w-6 after:tw-h-6 tw-p-0 tw-bg-transparent tw-border-none after:tw-bg-[url(/assets/images/header/close.png)] after:tw-bg-cover"
          />
          <a
            href="/"
            title="Motilal Oswal Asset Management Company"
          >
            <img
              src="/assets/images/footer/mo_mf_logo_blue.svg"
              alt="Motilal Oswal Asset Management Company"
              height={40}
              width={103}
            />
          </a>
        </div>

        <LoginButton variant="outlinedDark" />
      </div>

      {Object.keys(mobileHeaderLinks)?.map((category) => {
        const id = category.toLowerCase().split(" ").join("-");
        return (
          <div key={id} id={id}>
            <div
              className="tw-flex tw-items-center tw-my-4 tw-mx-5"
              onClick={() => handleClick(id)}
            >
              <h5 className="tw-text-base tw-leading-5 tw-font-medium tw-m-0">
                {category}
              </h5>
              <div
                className={`tw-ml-auto ${
                  openSection === id ? "after:-tw-rotate-180" : ""
                } after:tw-transition-all after:tw-block after:tw-w-[15px] after:tw-h-[15px] after:tw-bg-[url(/assets/images/header/arrow_down_thin.png)] after:tw-bg-cover`}
              />
            </div>
            <div
              className={`tw-flex tw-flex-col tw-transition-all tw-duration-300 tw-overflow-hidden ${
                openSection === id ? "tw-max-h-[2000px]" : "tw-max-h-0"
              }`}
            >
              {mobileHeaderLinks[category]?.map((item, i) => {
                if (item.isHeading) {
                  if (item.title === "Popular Funds") {
                    return (
                      <div
                        key={item.title}
                        className="tw-bg-[#EEF5FD] tw-flex tw-flex-col"
                      >
                        <h6 className="tw-text-sm tw-font-semibold tw-px-7 tw-pt-4 tw-pb-3 tw-m-0">
                          {item.title}
                        </h6>
                        {popularFunds?.map((fund) => (
                          <a
                            key={fund?.schDetail?.schName}
                            href={`/mutual-funds/${fund?.schDetail?.schNameURL}`}
                            title={fund?.schDetail?.schName}
                            className="tw-flex tw-gap-4 tw-items-center tw-text-sm tw-w-fit tw-mx-7 tw-my-3 tw-text-black"
                          >
                            <img
                              src={`${process.env.REACT_APP_NEXT_PUBLIC_IMAGE_PATH}${fund?.schCode}.png`}
                              className="tw-h-8 tw-w-8 tw-object-contain tw-shrink-0"
                              alt={fund?.schDetail?.schName}
                            />
                            {fund?.schDetail?.schName}
                          </a>
                        ))}
                      </div>
                    );
                  } else if (item.title === "Fund Types") {
                    return (
                      <div
                        key={item.title}
                        className="tw-flex tw-flex-col tw-gap-6 tw-border-t tw-mx-7 tw-mt-3 tw-pt-6 tw-pb-4"
                      >
                        {fundTypes?.map((type) => (
                          <a
                            key={type.title}
                            href={type.link}
                            title={type.title}
                            className="tw-relative tw-flex tw-items-center tw-gap-4 tw-bg-[#EEF5FD] tw-rounded-lg tw-font-medium tw-text-sm hover:tw-text-black tw-py-1 tw-px-2 after:tw-absolute after:tw-right-2 after:-tw-translate-y-1/2 after:tw-h-5 after:tw-w-5 after:tw-bg-[url(/assets/images/header/arrow_right.png)] after:tw-bg-contain"
                          >
                            <img
                              src={type.image}
                              height={32}
                              width={32}
                              alt={type.title}
                            />
                            <span>
                              {type.title}{" "}
                              {categoryFundCount[type.id] &&
                                "(" + categoryFundCount[type.id] + ")"}
                            </span>
                          </a>
                        ))}
                        <a
                          href={`/mutual-funds`}
                          title="View All Funds"
                          className="tw-relative tw-font-medium tw-text-sm tw-w-fit after:tw-absolute after:-tw-right-[28px] after:-tw-translate-y-1/2 after:tw-h-5 after:tw-w-5 after:tw-bg-[url(/assets/images/header/arrow_right.png)] after:tw-bg-contain"
                        >
                          View All Funds
                        </a>
                      </div>
                    );
                  } else if (item.title === "Important Links") {
                    return (
                      <div
                        key={item.title}
                        className="tw-bg-[#EEF5FD] tw-flex tw-flex-col tw-px-7"
                      >
                        <h6 className="tw-text-sm tw-font-semibold tw-pt-4 tw-pb-3 tw-m-0">
                          {item.title}
                        </h6>
                        {importantLinks?.map((link) => (
                          <a
                            key={link.title}
                            href={link.link}
                            title={link.title}
                            className="tw-text-sm tw-w-fit tw-text-black tw-py-3"
                            target={link.external ? "_blank" : "_self"}
                          >
                            {link.title}
                          </a>
                        ))}
                      </div>
                    );
                  } else if (item.title === "Dist Important Links") {
                    return (
                      <div
                        key={item.title}
                        className="tw-bg-[#EEF5FD] tw-flex tw-flex-col tw-px-7"
                      >
                        <h6 className="tw-text-sm tw-font-semibold tw-pt-4 tw-pb-3 tw-m-0">
                          Important Links
                        </h6>
                        {distImportantLinks?.map((link) => (
                          <a
                            key={link.title}
                            href={link.link}
                            title={link.title}
                            className="tw-text-sm tw-w-fit tw-text-black tw-py-3"
                            target={link.external ? "_blank" : "_self"}
                          >
                            {link.title}
                          </a>
                        ))}
                      </div>
                    );
                  } else
                    return (
                      <h6
                        key={item.title}
                        className="tw-text-sm tw-font-semibold tw-px-7 tw-py-3 tw-m-0"
                      >
                        {item.title}
                      </h6>
                    );
                } else {
                  return item?.image ? (
                    <div
                      key={`${item.title}-${i}`}
                      className="tw-flex tw-items-center tw-gap-4 tw-mx-7 tw-my-3"
                    >
                      <img
                        src={item.image}
                        height={32}
                        width={32}
                        alt={item.title}
                      />
                      <a
                        href={item.link}
                        title={item.title}
                        className="tw-text-sm tw-w-fit tw-text-black"
                        target={item.external ? "_blank" : "_self"}
                      >
                        {item.title}&nbsp;
                        {item.isFundCategory && categoryFundCount[item.title]
                          ? `(${categoryFundCount[item.title]})`
                          : ""}
                      </a>
                    </div>
                  ) : (
                    <a
                      key={`${item.title}-${item.link}`}
                      href={item.link}
                      title={item.title}
                      className="tw-text-sm tw-w-fit tw-text-black tw-mx-7 tw-my-3"
                      target={item.external ? "_blank" : "_self"}
                    >
                      {item.title}&nbsp;
                      {item.isFundCategory && categoryFundCount[item.title]
                        ? `(${categoryFundCount[item.title]})`
                        : ""}
                    </a>
                  );
                }
              })}
            </div>
          </div>
        );
      })}

        <div className='tw-border tw-border-greyBorder tw-mt-auto'>
          <div className='tw-mx-5 tw-py-5 tw-flex tw-flex-col '>
            <a
              href={"tel:+91 73049 21822"}
              title='+91 73049 21822'
              className='tw-text-sm tw-bg-white tw-text-login tw-border tw-border-greyBorder tw-font-semibold tw-w-full tw-py-3 tw-flex tw-items-center tw-justify-center tw-rounded-lg'
            >
              <img src="/assets/images/header/whatsapp-icon.png" height={28} width={28} alt="+91 73049 21822" />
              +91 73049 21822
            </a>
            <a
              href="/mutual-funds"
              title='Quick Invest'
              className='tw-text-sm tw-bg-red tw-text-white tw-font-semibold tw-w-full tw-py-3 tw-text-center tw-rounded-lg tw-bg-blue tw-mt-3'
            >
              Quick Invest
            </a>
          </div>
      </div>
    </div>
  );
};

export default MobileMenu;
